import "./NewsDetail.scss";
import message from "../icons/message.svg";
import calendar from "../icons/calendar.svg";
import ReviewSection from "../reviewSection/ReviewSection";
import Loader from "../../ui/Loader/Loader";
import { useTranslation } from "react-i18next";

const NewsDetails = ({ newsDetails, loadingDetails }) => {
  const [t] = useTranslation("global");
  const months = {
    "01": t("profile.January"),
    "02": t("profile.February"),
    "03": t("profile.March"),
    "04": t("profile.April"),
    "05": t("profile.May"),
    "06": t("profile.June"),
    "07": t("profile.July"),
    "08": t("profile.August"),
    "09": t("profile.September"),
    10: t("profile.October"),
    11: t("profile.November"),
    12: t("profile.December"),
  };
  return (
    <>
      {loadingDetails ? (
        <Loader />
      ) : (
        <div className="news-details page-padding">
          <h2>{newsDetails?.title}</h2>

          <div className="news-details__img">
            <img id="news-img" src={newsDetails?.image} alt="News" />
            <div className="news-details__date-and-reviews">
              <div className="news-details__date">
                <img src={calendar} alt="Calendar Icon" />
                <p>
                  {months[newsDetails?.date.slice(5, 7)]}{" "}
                  {newsDetails?.date.slice(5, 7).slice(0, 1) === "0"
                    ? newsDetails?.date.slice(6, 7)
                    : newsDetails?.date.slice(5, 7)}
                  , {newsDetails?.date.slice(0, 4)}
                </p>
              </div>

              <div className="news-details__reviews">
                <img src={message} alt="Message Icon" />
                <p>
                  {t("news.comments")}: {newsDetails?.reviews?.length}
                </p>
              </div>
            </div>
          </div>

          <div className="news-details__text">
            <h3>{newsDetails?.title}</h3>
            <p>{newsDetails?.text}</p>
          </div>
          <ReviewSection
            reviews={newsDetails?.reviews}
            news_id={newsDetails?.id}
          />
        </div>
      )}
    </>
  );
};

export default NewsDetails;
